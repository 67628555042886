import { useTransition } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import type { FieldPath } from "react-hook-form";
import type { TypeOf, ZodSchema } from "zod";

import { useZodForm } from "@/hooks/useZodForm";

export const useGetParams = () => {
  const searchParams = useSearchParams();
  return new URLSearchParams(searchParams.toString());
};

export const useRefreshSearchParams = <Z extends ZodSchema>(
  schema: Z,
  filters: FieldPath<TypeOf<Z>>[],
  defaultValues?: TypeOf<Z>,
) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const params = new URLSearchParams(searchParams.toString());
  const [isPending, startTransition] = useTransition();

  const form = useZodForm({ schema, defaultValues });

  const filtersValues = form.watch(filters);

  const selectedFilters = filters.reduce<
    {
      type: FieldPath<TypeOf<Z>>;
      value: string;
    }[]
  >((acc, filter, index) => {
    const filterValue = filtersValues[index];
    if (Array.isArray(filterValue)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
      const filterFormatted = filterValue.map((value: string) => ({
        value,
        type: filter,
      }));
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      acc.push(...filterFormatted);
    }
    return acc;
  }, []);

  const transition = () => {
    startTransition(() => {
      pathname &&
        router.replace(`${pathname}?${params.toString()}`, { scroll: false });
    });
  };

  const updatePage = (page: number) => {
    params.set("page", String(page));
    transition();
  };

  const updateFilter = (name: string, value: string[] | string) => {
    params.delete("page");

    if (!value) {
      params.delete(name);
    }
    if (typeof value === "string") {
      params.set(name, value);
    } else {
      value.length > 0
        ? params.set(name, value?.join("|") ?? "")
        : params.delete(name);
    }

    transition();
  };

  const updateQuery = (
    query: string,
    //b: string,
    page: string,
  ) => {
    params.delete("page");
    query !== "" ? params.set("query", query) : params.delete("query");
    parseInt(page) > 1 ? params.set("page", String(page)) : params.delete("page");

    transition();
  };

  return {
    isPending,
    updatePage,
    updateFilter,
    updateQuery,
    selectedFilters,
    form,
    params,
  };
};
