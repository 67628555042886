"use client";

import { useEffect } from "react";

import { usePageLocale } from "@/store/pageLocale";
import type { AllowedLanguages } from "@/types/generic";

interface PageLocaleProps {
  locale: AllowedLanguages;
}

// The purpose of this component is to store the page locale in the store so that
// we don't have to pass it down as a prop to every component that needs it.

const PageLocale = ({ locale }: PageLocaleProps) => {
  const { setPageLocale } = usePageLocale();

  useEffect(() => {
    setPageLocale(locale);
  }, [locale, setPageLocale]);

  return null;
};

export default PageLocale;
