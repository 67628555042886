"use client";

import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from 'react-share'
import {usePathname} from "next/navigation";

interface ShareButtonsProps {
    title: string;
    url?: string;
}

const ShareButtons = ({title, url}: ShareButtonsProps) => {

    const pathname = usePathname();
    if (!url) {
        url = `${process.env.NEXT_PUBLIC_BASE_URL}${pathname}`;
    }

    const shareButtonProps = {
        title,
        url,
        className: "bg-white hover:text-green transition border transition-colors hover:border-green rounded-full w-12 h-12 grid place-items-center ratio-square",
        resetButtonStyle: false,
    };

    const shareIconProps = {
        size: 32,
        bgStyle: { fill: "none" },
        borderRadius: 20,
    };

    return(
        <div className="my-4 flex flex-wrap gap-2 md:min-w-fit">
            <LinkedinShareButton {...shareButtonProps} >
                <LinkedinIcon {...shareIconProps} iconFillColor={"#0077b5"} />
            </LinkedinShareButton>

            <FacebookShareButton {...shareButtonProps} >
                <FacebookIcon {...shareIconProps} iconFillColor={"#1877f2"}  />
            </FacebookShareButton>

            <TwitterShareButton {...shareButtonProps}>
                <TwitterIcon {...shareIconProps} iconFillColor={"#1da1f2"} />
            </TwitterShareButton>

            <WhatsappShareButton {...shareButtonProps}>
                <WhatsappIcon {...shareIconProps} iconFillColor={"#25d366"} />
            </WhatsappShareButton>
        </div>
    )

}
export default ShareButtons