"use client";

import {useRouter} from "next/navigation";

import Icon from "@/components/atoms/Icon";
import {cn} from "@/helpers/className";
import { generateHomepageUrl } from "@/helpers/url"
import { useTargetAudience } from "@/store/targetAudience.ts"
import { AllowedLanguages } from "@/types/generic"

interface BackToSearchProps {
  entitySlug: string;
  light?: boolean;
  locale: AllowedLanguages;
  referer?: string | null;
  title: string;
}

export const BackToSearch = ({
                               locale,
                               referer,
                               title,
                               entitySlug,
                               light,
                             }: BackToSearchProps) => {
  const router = useRouter();
  const { targetAudience } = useTargetAudience();

  const goBack = () => {
    const homeUrl = generateHomepageUrl(targetAudience, locale);
    const searchUrl = `${homeUrl}/${entitySlug}`;
    const historyHasAnyPage = window.history?.length > 1;

    if (referer && historyHasAnyPage) {
      const refererHostname = new URL(referer).hostname;
      const refererPathname = new URL(referer).pathname;
      const currentHostname = window.location.hostname;

      if (refererHostname === currentHostname && refererPathname.startsWith(searchUrl)) {
        router.back();
        return;
      } else {
        console.log(
          "Referer is not the same as current hostname and pathname",
          refererHostname,
          currentHostname,
          refererPathname,
          searchUrl
        )
      }
    }

    router.push(searchUrl);
  };

  return (
    <button
      onClick={goBack}
      className={cn(
        'flex items-center gap-2 text-black text-sm',
        light && 'text-white'
      )}
      data-referer={!!referer}
    >
      <Icon name="angle-left" className="aspect-square w-2"/>
      {title}
    </button>
  );
};
