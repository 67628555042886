"use client";

import { useEffect } from "react";

import { useTargetAudience } from "@/store/targetAudience";
import { AvailableTargetAudience } from "@/types/generic";

interface TargetAudiencePathProps {
  targetAudienceLabel: string;
}

const TargetAudience = ({ targetAudienceLabel }: TargetAudiencePathProps) => {
  const { setTargetAudience } = useTargetAudience();
  let targetAudience = AvailableTargetAudience.EMPLOYEE;
  if (targetAudienceLabel === "Werkgever") {
    targetAudience = AvailableTargetAudience.EMPLOYER;
  }
    useEffect(() => {
        setTargetAudience(targetAudience);
    }, [targetAudience, setTargetAudience]);

  return null;
};

export default TargetAudience;
