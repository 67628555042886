import Icon from "@/components/atoms/Icon";
import { cn } from "@/helpers/className";

interface DateLabelProps {
  className?: string;
  iconClassName?: string;
  iconWrapperClassName?: string;
  dateLabel: string;
}

export const DateLabel = ({
  dateLabel,
  className,
  iconClassName,
  iconWrapperClassName,
}: DateLabelProps) => {
  return (
    <p
      className={cn(
        "mb-1 flex items-center gap-2 text-xs text-gray-500",
        className,
      )}
    >
      <span
        className={cn(
          "inline-flex items-center justify-center",
          iconWrapperClassName,
        )}
      >
        <Icon name="calendar" className={cn("h-4 w-4", iconClassName)} />
      </span>
      {dateLabel}
    </p>
  );
};
