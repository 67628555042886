import Image from "next/image";
import Link from "next/link";

import type { AllowedLanguages } from "@/types/generic";
import type { BlogOverviewGraphqlResult } from "@/graphql/sdk/__generated__";
import logo from "@/assets/images/logo-vivaldis.svg";
import {cn} from "@/helpers/className";
import {DateLabel} from "@/components/atoms/DateLabel";
import Tag from "@/components/atoms/Tag";

interface BlogCardProps {
  locale: AllowedLanguages;
  blog: BlogOverviewGraphqlResult;
  i18n: {
    read_more: string;
  };
  extraClasses?: string;
}

export const BlogCard = ({ locale, blog, i18n, extraClasses }: BlogCardProps) => {
  const blogDate = new Date(blog.created.timestamp * 1000);
  const formattedBlogDate = blogDate.toLocaleDateString(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
  return (
    <div
      key={blog.id}
      className={cn(
          extraClasses,
          "blog-teaser relative flex flex-col items-stretch rounded-lg border bg-white transition-all hover:border-yellow-600 hover:shadow-lg",
      )}
    >
      <div className="relative w-full aspect-video overflow-hidden rounded-t-lg bg-yellow flex shrink-0 items-center justify-center">
        {blog.teaserMedia?.imageMediaImage?.variations[2] ? (
            <Image
                src={blog.teaserMedia.imageMediaImage.variations[2].url} // Use the URL of the first variation
                fill
                alt={blog.title}
                sizes="450px"
                className={"object-cover absolute h-full w-full"}
            />
        ) : (
            <Image
                src={logo}
                width={80}
                height={42}
                alt="Vivaldis Interim"
                className="w-[160px]"
            />
        )}
      </div>

      <div className="flex flex-1">
        <div className="p-6 lg:p-8 w-full h-full flex flex-col">
          {formattedBlogDate && (
            <DateLabel
                dateLabel={formattedBlogDate}
                className="relative z-10 text-base"
                iconClassName="text-green"
            />
          )}

          {blog.blogCategoryRef && (
              <div className="mt-2 mb-4 flex flex-wrap gap-2">
                {blog.blogCategoryRef.map((category, index) => {
                  return (
                    <Tag key={index}  color={"gray"} icon={"tag"} small={true}>
                      {category.name}
                    </Tag>
                  );
                })}
              </div>
          )}

          {blog.title && (
          <h4 className={cn("h5 mt-3 !mb-1 break-words")}>
            {blog.title}
          </h4>
          )}

          {blog.intro && (
              <div
                  className={cn(
                      "prose line-clamp-2 max-w-none text-sm lg:text-base max-w-full mt-2 mb-4",
                  )}
                  dangerouslySetInnerHTML={{
                    __html: blog.intro.processed.replace(/<\/?[^>]+(>|$)/g, ""),
                  }}
              ></div>
          )}

          <Link
            href={blog.path}
            className="stretched-link mt-auto pt-4 mr-auto"
          >
            <span className="link text-sm no-underline pb-1">{i18n.read_more}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
