'use client';
import {useTranslation} from "@/i18n/client";
import type { AllowedLanguages } from "@/types/generic";
import type { BlogOverviewGraphqlResult } from "@/graphql/sdk/__generated__";
import { BlogCard } from "@/components/molecules/BlogCard";
import {cn} from "@/helpers/className";

interface BlogListProps {
  locale: AllowedLanguages;
  className?: string;
  results: BlogOverviewGraphqlResult[];
}

export const BlogList = ({ results, locale, className }: BlogListProps) => {
  const {t} = useTranslation(locale);
  return (
    <div className={cn('blog-list-items grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-4 grid lg:gap-8', className)}>
      {results.map((blog, index) => {
        const delay = (index + 1) * 200;
        return (
            <BlogCard
                locale={locale}
                blog={blog}
                extraClasses={`blog-teaser--overview animate-fadeInUp animate-delay-[${delay}]`}
                key={blog.id}
                i18n={{
                  read_more: t("blog.read_more")
                }}
            />
        );
      })}
    </div>
  );
};
