import React from "react";

import { cn } from "@/helpers/className";

interface SausageProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
  children: React.ReactNode;
  className?: string;
  count?: number;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Sausage = ({
  active,
  children,
  className,
  count,
  onClick,
  ...props
}: SausageProps) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={cn(
        "inline-flex h-8 items-center gap-2 rounded-full p-3 text-xs ring-2 ring-inset ring-white transition hover:bg-white",
        active && "bg-primary-100/20 hover:bg-primary-100/40",
        className,
      )}
      {...props}
    >
      {children}
      {count ? (
        <span className="bg-primary inline-flex h-4 w-4 items-center justify-center rounded-full bg-white text-[10px]">
          {count}
        </span>
      ) : null}
    </button>
  );
};
