import { Fragment, MutableRefObject, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

import Icon from "@/components/atoms/Icon";

interface FiltersSidebarProps {
  children: React.ReactNode;
  clickedFilter?: string;
  filtersOpen: boolean;
  i18n: {
    clear_filters: string;
    close_sidebar: string;
    entity_title: string;
    show: string;
  };
  resultCount?: number;
  setFiltersOpen: (open: boolean) => void;
  customCountLabel: string;
}

export const FiltersSidebar = ({
  filtersOpen,
  setFiltersOpen,
  clickedFilter,
  i18n,
  children,
  resultCount,
  customCountLabel,
}: FiltersSidebarProps) => {
  const sidebar: MutableRefObject<HTMLElement | null> = useRef(null);

  const clearFilters = () => {
    // Ideally we reset the form here and use router.push, but couldn't find an easy way to reset the form
    // Possible solution is to create a separate component for the reset button, which can then use useFormContext()
    window.location.href = window.location.origin + window.location.pathname;
  };

  const scrollToClickedFilter = () => {
    if (clickedFilter) {
      const element = document.getElementById(`mobile_filter-${clickedFilter}`);
      if (element && sidebar.current) {
        const scrollOffset = -10;
        const y = element.getBoundingClientRect().top + scrollOffset;

        sidebar.current.scrollTo({
          top: y,
          // behavior: 'smooth'
        });
      }
    }
  };

  return (
    <Transition.Root show={filtersOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setFiltersOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-100"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
                unmount={false}
                beforeEnter={scrollToClickedFilter}
              >
                <Dialog.Panel className="pointer-events-auto w-screen sm:max-w-md">
                  <div
                    className="flex h-full flex-col overflow-y-auto bg-white shadow-xl"
                    ref={sidebar}
                  >
                    <div className="flex items-center px-4 pt-6 sm:px-6">
                      <button
                        type="button"
                        className="ml-auto text-gray-700"
                        onClick={() => setFiltersOpen(false)}
                      >
                        <span className="sr-only">{i18n.close_sidebar}</span>
                        <Icon name="close" className="h-5 w-5 text-black-300" />
                      </button>
                    </div>

                    {/* Filters */}
                    <div className="relative mb-10 flex flex-1 flex-col gap-8 px-4 sm:px-6">
                      {children}
                    </div>

                    <div className="sticky bottom-0 mt-auto flex items-center justify-between gap-4 border-t bg-background px-6 py-4">
                      <button
                        className="text-sm text-black-400 underline transition-colors hover:text-black"
                        onClick={clearFilters}
                        type={"button"}
                      >
                        {i18n.clear_filters}
                      </button>
                      <button
                        type="submit"
                        className="btn btn-green"
                        onClick={() => setFiltersOpen(false)}
                      >
                        {customCountLabel ? customCountLabel : `${i18n.show} ${resultCount} ${i18n.entity_title}`}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
